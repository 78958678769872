import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowCircleLeft, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from 'styled-components';

import { GraficoBarra } from '../../../components/GraficoBarra';
import Header from '../../../components/Header';
import { Line } from '../../../components/Line';
import { fetchPerfHist, fetchPerfHistPerf } from '../../../services/api/perfHistAPI';
import { useAppDispatch, useAppSelector } from '../../../services/reduxHooks';
import EnhancedTable from '../../../components/Table';
import { AlignTypes, ITableColumns } from '../../../components/Table/TableHeader';
import { auxFormatPercent, helperFormat, FormatTypes } from '../../../services/helper';
import { Grid, Icon, IconButton, Switch, Typography } from '@mui/material';
import { Percent } from '@mui/icons-material';
import { Subtitulo } from '../../../components/Titulo';
import { CustomModal } from '../../../components/CustomModal';
import GraficoLinha from '../../../components/GraficoLinha';
import GraficoArea from '../../../components/GraficoArea';
import LoadingModal from '../LoadingModal';
import { sendAnalyticsData } from '../../../services/api/preferenceAPI';
import {
  IRendimentoSubclasse,
  setSelectedBenchmarks,
} from '../../../services/reducers/perfHistSlice';
import { fetchResumoCart } from '../../../services/api/resumoCartAPI';
import { fetchEstats } from '../../../services/api/estatsAPI';
import { fetchPosCon } from '../../../services/api/posConAPI';
import { fetchIFsCaixa } from '../../../services/api/fluxoCaixaAPI';
// import { fetchCartExp } from '../../../services/api/cartExpAPI';
import { fetchFluxoAtivos } from '../../../services/api/fluxoAtivosAPI';
import { selecionarBenchmarks } from '../../../services/helper';
import { styled } from '@mui/material/styles';
import MoneyPrefix from '../../../components/MoneyPrefix';
import FixedLoadingButton from '../../../components/FixedLoadingButton';
import { useNavigate, useLocation } from 'react-router-dom';

interface Props {
  dispatchPDF?: boolean;
}

export default function PerformanceHistorica({ dispatchPDF }: Props) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const uselocation = useLocation();

  const [
    obj_datas,
    dadosMesaMes,
    graficoRetornos,
    graficoPL,
    movimentacoes,
    rendimento12meses,
    loading,
    loadingPercent,
    isLoaded,
    selectedBenchmarks,
    carteiraList,
    loadedCarteiraList,
    carteira,
    isLoadingMinimized,
    params,
    dadosPercentPerf,
  ] = useAppSelector((state) => [
    state.perfHist.obj_datas,
    state.perfHist.dadosMesaMes,
    state.perfHist.graficoRetornos,
    state.perfHist.graficoPL,
    state.perfHist.movimentacoes,
    state.perfHist.rendimento12meses,
    state.perfHist.loading,
    state.perfHist.loadingPercent,
    state.perfHist.isLoaded,
    state.perfHist.selectedBenchmarks,
    state.preference.carteiraList,
    state.preference.loadedCarteiraList,
    state.preference.params.carteira,
    state.loadMinimized.isLoadingMinimized,
    state.preference.params,
    state.perfHist.dadosPercentPerf,
  ]);

  /**
   * Selecionar benchmarks
   * @param newBenchmarks Benchmarks selecionados
   * @param historyReplace Indica se a nova navega��o no state do History vai adicionar uma nova entrada ou substituir a atual no history Stack
   * No caso do benchmark inicial(preferencia do usuario), a entrada vai substituir o state atual, adicionando os benchmarks no state
   * No caso dos benchmarks selecionados, ser� adicionado uma nova entrada na History, para guardar a navega��o desses benchmarks no history Stack
   */
  const dispatchSelectedBenchmarks = (newBenchmarks: string[], historyReplace = false) => {
    const url = `?carteira=${carteira}&periodo=${params.periodo}`
    // o par�metro replace permite alterar o state sem adicionar uma nova entrada na history stack
    navigate(url, { replace: historyReplace, state: { ...uselocation.state, bench_perf_hist: newBenchmarks } });   // adiciona os benchmarks selecionados no state do History router
    dispatch(setSelectedBenchmarks(newBenchmarks));
  }

  useEffect(() => {
    if (!dispatchPDF) document.title = `${process.env.REACT_APP_TABNAME} - Performance Histórica`;
  }, []);

  useEffect(() => {
    selecionarBenchmarks(loadedCarteiraList, carteiraList, carteira, dispatchPDF, selectedBenchmarks, 'PerfHist', dispatchSelectedBenchmarks);
  }, [loadedCarteiraList]);

  useEffect(() => {
    if (isLoaded && !dispatchPDF) dispatch(sendAnalyticsData(location.href));
  }, [params, isLoaded]);

  useEffect(() => {
    async function loadDados() {
      await dispatch(fetchPerfHist());
    }
    if (loadedCarteiraList && !isLoaded && !loading && !dispatchPDF) {
      loadDados();

      dispatch(fetchResumoCart());
      dispatch(fetchPosCon());
      // dispatch(fetchCartExp());
      dispatch(fetchFluxoAtivos('mes_atual'));
      dispatch(fetchIFsCaixa());
      dispatch(fetchEstats());
    }
  }, [isLoaded, loadedCarteiraList]);

  /**
   * Carregar os benchmarks quando a p�gina for recarregada
   */
  useEffect(() => {
    const bench_history = uselocation?.state?.bench_perf_hist ?? null;
    if(bench_history){
      dispatch(setSelectedBenchmarks(bench_history));
    }
  }, []);

  const SiglaMes = [
    'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez',
  ];

  const assetsApi = isLoaded && graficoRetornos ? (graficoRetornos[0].slice(1) as string[]) : null;
  const dados = isLoaded && graficoRetornos ? graficoRetornos.slice(1) : null;
  const dadosEvol = isLoaded && graficoPL ? graficoPL.slice(1) : null;
  const datesApi =
    isLoaded && graficoRetornos
      ? (graficoRetornos.slice(1).map((item) => item[0]) as string[])
      : null;
  const datesTooltip = datesApi?.map((item) => item.split('-').reverse().join('/'));

  const datesAnoMes = datesApi?.map((item) => (item as string).substring(2, 7));
  datesAnoMes?.reverse();
  const dates: string[] = [];
  datesAnoMes?.forEach(function (item, i) {
    dates[i] = `${SiglaMes[Number(item.substring(3, 5)) - 1]}/${item.substring(0, 2)}`;
  });
  dates.reverse();

  const data: any = [];
  const dataEvol: any = [];
  dates.forEach((item, i) => data.push({ name: item, datesTooltip: datesTooltip![i] }));
  dates.forEach((item, i) => dataEvol.push({ name: item, datesTooltip: datesTooltip![i] }));

  assetsApi?.forEach(function (itemAsset, indexAsset) {
    if (itemAsset === 'Carteira_retorno') {
      dataEvol.forEach(function (itemData: any, i: number) {
        itemData[assetsApi[0]] = dadosEvol![i][1];
      });
    }
    data.forEach(function (itemData: any, i: number) {
      itemData[itemAsset] = dados![i][indexAsset + 1];
    });
  });

  const moneyPrefix = MoneyPrefix(carteiraList, params.carteira);

  const [openModal, setOpenModal] = useState(false);
  const [periodoModal, setPeriodoModal] = useState('');
  const [isPercentToggle, setIsPercentToggle] = useState(false);
  const [tabelaSubPercent, setTabelaSubPercent] = useState(false);
  const [classPeriodData, setClassPeriodData] = useState<{
    class: string;
    value: number | null;
    data: IRendimentoSubclasse[];
  }>({ class: '', value: null, data: [] });
  const [viewClassOnPeriod, setViewClassOnPeriod] = useState(false);

  const onBarClickHandle = (value: any) => {
    setOpenModal(!openModal);
    if (value?.payload?.name) {
      setViewClassOnPeriod(false);
      setPeriodoModal(value?.payload?.name);
    }
  };
  const onSecondBarHandle = (value: any) => {
    setViewClassOnPeriod(true);
    setClassPeriodData({
      class: value?.payload.name,
      value: value?.payload.value,
      data: value?.payload?.auxTableData ?? [],
    });
  };

  const barChartRend12Data =
    isLoaded && rendimento12meses
      ? Object.keys(rendimento12meses)
          .reverse()
          .map((mes) => {
            const value = rendimento12meses[mes][0].rend;
            return {
              name: mes,
              value:
                typeof value === 'number'
                  ? Number(value.toFixed(2))
                  : Number(Number(value).toFixed(2)),
            };
          })
      : null;

  const barChartModal =
    isLoaded && rendimento12meses && periodoModal !== ''
      ? rendimento12meses[periodoModal]
          .slice(1)
          .filter((obj) => obj.rend !== 0 && obj.rend !== '' && obj.rend !== 'nd')
          .map((obj) => {
            return {
              name: obj.classe,
              value:
                typeof obj.rend === 'number'
                  ? Number(obj.rend.toFixed(2))
                  : Number(Number(obj.rend).toFixed(2)),
              auxTableData: obj.rend_subclasse,
            };
          })
      : null;

  const dadosPercentPerfPages = dadosPercentPerf?.[`perf_${periodoModal}`] ?? [];

  const barChartModalPercent: any[] = [];
  dadosPercentPerfPages.slice(1).forEach((obj: any) => {
    barChartModalPercent.push({
      name: obj.classe,
      value: obj.perf,
    });
  });

  const rowsTabelaPorClasse: { [key: string]: any }[] = [];
  classPeriodData.data.forEach((obj) => {
    rowsTabelaPorClasse.push({
      ativo: obj.subclasse,
      rend: helperFormat(obj.rend, FormatTypes.monetary, 2, moneyPrefix),
      bgColor: '#E7E7E7',
      color: theme.fontColor,
      bold: true,
    });

    obj.rend_ativo.forEach((ativo) => {
      rowsTabelaPorClasse.push({
        ativo: ativo.ativo,
        rend: helperFormat(ativo.rend, FormatTypes.monetary, 2, moneyPrefix),
      });
    });
  });

  if (classPeriodData.data.length > 0) {
    rowsTabelaPorClasse.unshift({
      ativo: `Total ${classPeriodData.class}`,
      rend: helperFormat(classPeriodData.value, FormatTypes.monetary, 2, moneyPrefix),
      bgColor: '#444444',
      color: theme.backgroundColor,
      bold: true,
    });
  }

  const rowsTabelaPorClassePercent: { [key: string]: any }[] = [];
  dadosPercentPerfPages.slice(1).forEach((objs) => {
    if (classPeriodData.class === objs.classe && objs.perf_subclasse) {
      objs.perf_subclasse.forEach((obj) => {
        rowsTabelaPorClassePercent.push({
          ativo: obj.subclasse,
          rend: helperFormat(obj.perf, FormatTypes.percentage, 2),
          bgColor: '#E7E7E7',
          color: theme.fontColor,
          bold: true,
        });

        obj.perf_ativo.forEach((ativo) => {
          rowsTabelaPorClassePercent.push({
            ativo: ativo.ativo,
            rend: helperFormat(ativo.perf, FormatTypes.percentage, 2),
          });
        });
      });

      if (rowsTabelaPorClassePercent.length > 0) {
        rowsTabelaPorClassePercent.unshift({
          ativo: `Total ${objs.classe}`,
          rend: helperFormat(objs.perf, FormatTypes.percentage, 2),
          bgColor: '#444444',
          color: theme.backgroundColor,
          bold: true,
        });
      }
    }
  });

  const colsTabelaPorClasse: ITableColumns[] = [
    {
      id: 'ativo',
      label: 'Ativo',
      width: '300px',
    },
    {
      id: 'rend',
      label: `${isPercentToggle ? 'Performance' : 'Rendimento'} em ${periodoModal}`,
      align: AlignTypes.right,
    },
  ];

  interface GroupedDataRetornos {
    [year: number | string]: (number | string)[][];
  }

  const dadosMesaMesAgrupados: GroupedDataRetornos = dadosMesaMes.reduce(
    (accum: GroupedDataRetornos, current: (number | string)[]) => {
      const year: number | string = current[0];

      if (!year || year === '') return accum;

      const values: (number | string)[] = current.slice(1);

      if (!accum[year]) accum[year] = [];
      accum[year].push(values);
      return accum;
    },
    {} as GroupedDataRetornos,
  );

  const columnsRetornos: ITableColumns[] = [
    {
      id: 'ano',
      label: '',
      rowSpan:
        Object.keys(dadosMesaMesAgrupados).length <= 0
          ? 0
          : dadosMesaMesAgrupados[Object.keys(dadosMesaMesAgrupados)[0]].length,
      bgColor: theme.titleFontColor,
      color: 'white',
      padding: '4px',
      align: AlignTypes.center,
      borderLeftRadius: '5px',
      freeze: true,
    },
    {
      id: 'ativo',
      label: 'Ativo',
      freeze: true,
      leftOnFreeze: 40,
      noBreak: true,
    },
    { id: 'Jan', label: 'Jan', align: AlignTypes.right },
    { id: 'Fev', label: 'Fev', align: AlignTypes.right },
    { id: 'Mar', label: 'Mar', align: AlignTypes.right },
    { id: 'Abr', label: 'Abr', align: AlignTypes.right },
    { id: 'Mai', label: 'Mai', align: AlignTypes.right },
    { id: 'Jun', label: 'Jun', align: AlignTypes.right },
    { id: 'Jul', label: 'Jul', align: AlignTypes.right },
    { id: 'Ago', label: 'Ago', align: AlignTypes.right },
    { id: 'Set', label: 'Set', align: AlignTypes.right },
    { id: 'Out', label: 'Out', align: AlignTypes.right },
    { id: 'Nov', label: 'Nov', align: AlignTypes.right },
    { id: 'Dez', label: 'Dez', align: AlignTypes.right },
    { id: 'Ano', label: 'Ano', align: AlignTypes.right },
    { id: 'Acum', label: 'Acum', align: AlignTypes.right },
  ];

  const rowsRetornos =
    isLoaded && dadosMesaMesAgrupados
      ? Object.entries(dadosMesaMesAgrupados).flatMap(([year, data]) =>
          data.map((subArray, index) => {
            let i = 0;
            const rowData: { [key: string]: string | number } = {
              ativo: subArray[i++],
              Jan: helperFormat(auxFormatPercent(subArray[i++]), FormatTypes.decimals, 2),
              Fev: helperFormat(auxFormatPercent(subArray[i++]), FormatTypes.decimals, 2),
              Mar: helperFormat(auxFormatPercent(subArray[i++]), FormatTypes.decimals, 2),
              Abr: helperFormat(auxFormatPercent(subArray[i++]), FormatTypes.decimals, 2),
              Mai: helperFormat(auxFormatPercent(subArray[i++]), FormatTypes.decimals, 2),
              Jun: helperFormat(auxFormatPercent(subArray[i++]), FormatTypes.decimals, 2),
              Jul: helperFormat(auxFormatPercent(subArray[i++]), FormatTypes.decimals, 2),
              Ago: helperFormat(auxFormatPercent(subArray[i++]), FormatTypes.decimals, 2),
              Set: helperFormat(auxFormatPercent(subArray[i++]), FormatTypes.decimals, 2),
              Out: helperFormat(auxFormatPercent(subArray[i++]), FormatTypes.decimals, 2),
              Nov: helperFormat(auxFormatPercent(subArray[i++]), FormatTypes.decimals, 2),
              Dez: helperFormat(auxFormatPercent(subArray[i++]), FormatTypes.decimals, 2),
              Ano: helperFormat(auxFormatPercent(subArray[i++]), FormatTypes.decimals, 2),
              Acum: helperFormat(auxFormatPercent(subArray[i++]), FormatTypes.decimals, 2),
            };
            if (index === 0) rowData.ano = year;

            return rowData;
          }),
        )
      : null;

  const columnsMovim: ITableColumns[] = [
    { id: 'mesano', label: 'Mês/Ano', freeze: true },
    { id: 'saldoIni', label: 'Saldo Inicial (A)', align: AlignTypes.right },
    { id: 'aplicacoes', label: 'Aplicações (B)', align: AlignTypes.right },
    { id: 'resgates', label: 'Resgates (C)', align: AlignTypes.right },
    { id: 'impostos', label: 'Impostos Pagos (D)', align: AlignTypes.right },
    { id: 'saldoMovim', label: 'Saldo Movimentações (E=B-C)', align: AlignTypes.right },
    { id: 'rendNominal', label: 'Rendimento Nominal (F)', align: AlignTypes.right },
    { id: 'saldoFim', label: 'Saldo Final (G=A-D+E+F)', align: AlignTypes.right },
  ];

  const rowsMovim =
    isLoaded && movimentacoes
      ? movimentacoes.map((movim) => {
          return {
            mesano: movim.data,
            saldoIni: helperFormat(movim.saldoBrutoIni, FormatTypes.decimals),
            aplicacoes: helperFormat(movim.apl, FormatTypes.decimals),
            resgates: helperFormat(movim.res, FormatTypes.decimals),
            impostos: helperFormat(movim.imp, FormatTypes.decimals),
            saldoMovim: helperFormat(movim.saldoMovim, FormatTypes.decimals),
            rendNominal: helperFormat(movim.rend, FormatTypes.decimals),
            saldoFim: helperFormat(movim.saldoBruto, FormatTypes.decimals),
          };
        })
      : null;

  useEffect(() => {
    setIsPercentToggle(false);
    setTabelaSubPercent(false);
  }, [openModal]);

  const handleToggleModal = async () => {
    await dispatch(fetchPerfHistPerf(periodoModal));
    setIsPercentToggle(!isPercentToggle);
    setTabelaSubPercent(!tabelaSubPercent);
  };

  const toggleColorAbsolute = isPercentToggle ? 'gray' : theme.iconsColor;
  const toggleColorPercent = isPercentToggle ? theme.iconsColor : 'gray';

  const StyledSwitch = styled(Switch)(() => ({
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: theme.iconsColor,
    },
  }));

  return (
    <>
      <Grid>
        <Header
          title="Performance Histórica"
          containerAux
          benchmarks={{ selectedBenchmarks, dispatchSelectedBenchmarks }}
          periodo
          obj_datas={obj_datas}
        />
        <Line />
      </Grid>
      <Grid container spacing={3} sx={{ marginTop: 3 }}>
        <Grid item xs={12}>
          <Subtitulo mb="8px">Retornos Nominais da Carteira x Benchmarks</Subtitulo>
          <EnhancedTable
            rows={rowsRetornos}
            columns={columnsRetornos}
            noScroll={true}
            height={500}
          />
        </Grid>
        <Grid item xs={12}>
          <Subtitulo mb="8px">Aplicações e Resgates</Subtitulo>
          <EnhancedTable rows={rowsMovim} columns={columnsMovim} height={500} noScroll={true} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid>
            <Subtitulo mb="8px">Retorno Acumulado</Subtitulo>
          </Grid>
          <GraficoLinha
            data={data}
            tipo={FormatTypes.percentage}
            assets={assetsApi}
            moneyPrefix={moneyPrefix}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid>
            <Subtitulo mb="8px">Evolução do Patrimônio</Subtitulo>
          </Grid>
          <GraficoArea
            data={dataEvol}
            tipo={FormatTypes.monetary_extense}
            assets={['Carteira_retorno']}
            moneyPrefix={moneyPrefix}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid>
            <Subtitulo mb="8px">Ganho Financeiro (últimos 12m)</Subtitulo>
          </Grid>
          <GraficoBarra
            data={barChartRend12Data}
            decimals={2}
            moneyPrefix={moneyPrefix}
            colors={theme.barChartColor}
            onClickHandle={onBarClickHandle}
            height={300}
            isPercentToggle={false}
          />
        </Grid>
      </Grid>
      <CustomModal
        open={openModal}
        onCloseHandle={onBarClickHandle}
        width={viewClassOnPeriod ? 'fit-content' : '80%'}>
        {viewClassOnPeriod ? (
          <div>
            <div
              onClick={() => setViewClassOnPeriod(false)}
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                width: 'fit-content',
              }}>
              <FontAwesomeIcon
                icon={faArrowCircleLeft as IconProp}
                style={{ color: theme.backgroundColorMain, margin: '8 8 8 0' }}
                size="lg"
              />
              Voltar
            </div>
            <EnhancedTable
              rows={isPercentToggle ? rowsTabelaPorClassePercent : rowsTabelaPorClasse}
              columns={colsTabelaPorClasse}
              height={300}
            />
          </div>
        ) : (
          <>
            <Grid container>
              <Grid item>
                <Subtitulo mb="8px">
                  {isPercentToggle ? 'Atribuição de Performance' : 'Contribuição Financeira'} no
                  Mês ({periodoModal})
                  {rendimento12meses[periodoModal]
                    ? ` - Total da carteira: ${
                        isPercentToggle
                          ? helperFormat(
                              dadosPercentPerfPages && dadosPercentPerfPages[0]
                                ? dadosPercentPerfPages[0].perf
                                : '',
                              FormatTypes.percentage,
                              2,
                              moneyPrefix,
                            )
                          : helperFormat(
                              rendimento12meses[periodoModal][0]?.rend,
                              FormatTypes.monetary,
                              2,
                              moneyPrefix,
                            )
                      }`
                    : ''}{' '}
                </Subtitulo>
              </Grid>
              <Grid item style={{ marginLeft: 'auto' }}>
                <IconButton style={{ color: toggleColorAbsolute }} onClick={handleToggleModal}>
                  <FontAwesomeIcon
                    icon={faDollarSign as IconProp}
                    style={{ color: toggleColorAbsolute }}
                    size="xs"
                  />
                </IconButton>
                <StyledSwitch
                  checked={isPercentToggle}
                  onChange={handleToggleModal}
                  style={{ color: theme.iconsColor }}
                />
                <IconButton style={{ color: toggleColorPercent }} onClick={handleToggleModal}>
                  <Percent />
                </IconButton>
              </Grid>
            </Grid>
            <GraficoBarra
              data={isPercentToggle ? barChartModalPercent : barChartModal}
              decimals={2}
              moneyPrefix={moneyPrefix}
              colors={theme.chartColors}
              onClickHandle={onSecondBarHandle}
              height={500}
              hideXAxisLabels={true}
              isPercentToggle={isPercentToggle}
            />
          </>
        )}
      </CustomModal>
      <LoadingModal loading={(!isLoaded && !isLoadingMinimized) ?? false} />
      <LoadingModal loading={(loadingPercent && !isLoadingMinimized) ?? false} />
      <FixedLoadingButton loading={(!isLoaded || loadingPercent) ?? false} isLoadingMinimized={isLoadingMinimized}/>
    </>
  );
}
